html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
}

.App-header {
  color: #F5F5F5;
  background-color: #5E1C65;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; /* Default padding for mobile */
}

.logo {
  display: flex;
  align-items: center;
}

.App-header h3 {
  margin-left: 10px; 
  margin-top: 20px; 
  margin-bottom: 2px; 
  font-family: 'Poppins', sans-serif;
}

@media (min-width: 768px) {
  .App-header {
    padding: 0 5rem; /* Adjusted padding for desktop views */
  }
}


.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem; /* Default padding for mobile */
}

@media (min-width: 768px) {
  .content-container {
    padding: 0 5rem; /* Adjusted padding for desktop views */
  }
}

p, pre {
  font-size: small;
  color: #34495e;
}
.status {
  font-size: 1.1em;
  font-weight: bold;
}

.machine-log {
  color: #333;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #eeeeee;
  border-radius: 1px;
}


.console-output {
  background-color: #ddf9ff28;
  padding: 10px;
  margin-top: 10px;
  white-space: pre-wrap;
  overflow-x: auto;
  font-family: 'Consolas', Courier, monospace;
}

.machine-log button {
  background: none;
  border: none;
  color: inherit;
  margin: -0.5rem;
  padding: 0.5rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em; 
}

.detail-label {
  width: 6rem; 
  flex-shrink: 0;
  margin: 0 0 0 0;
}

/* Dark mode styles */
.dark-mode {
  background-color: #000;
  color: #bdbdbd;
}

.dark-mode .App-header {
  background-color: #000;
  color: #bdbdbd;
}

.dark-mode .machine-log {
  background-color: #181818;
  color: #bdbdbd;
}

.dark-mode .console-output {
  background-color: #2f2f2f36;
  color: #bdbdbd;
}

.dark-mode .App-header .dark-mode-button {
  color: #bdbdbd;
}

.dark-mode p, pre {
  color: #bdbdbd;
}


.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,  
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

.symbol-running {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' -25,
    'opsz' 24;
  animation: rotate 5s linear infinite;
  margin-left: 0.3rem; 
}

.dark-mode .symbol-running {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' -25,
  'opsz' 24;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.symbol-completed {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 200,
    'opsz' 24;
  margin-left: 0.25rem; 
}

.symbol-stalled {
  font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 24;
  margin-left: 0.3rem; 
}

.symbol-interrupted {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' -25,
    'opsz' 24;
  margin-left: 0.3rem; 
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(165deg, #5E1C65 50% , #830192)
}


.splash-screen h3 {
  color: #F5F5F5;
  margin-left: 10px; 
  margin-top: 25px; 
  margin-bottom: 5px; 
  font-family: 'Poppins', sans-serif;
}

.splash-screen form {
  padding: 15px 0;
  width: 280px
}

.splash-screen .access-code-input,
.splash-screen .submit-button {
  height: 30px; 
  padding: 6px 12px; 
  border: 1px solid #ccc; 
  box-sizing: border-box; 
  text-align: center;
  line-height: normal; 
  vertical-align: middle; 
}

.splash-screen .access-code-input {
  width: 210px; 
}

.splash-screen .access-code-input:focus {
  outline: 2px solid #E4A2F1;
  position: relative;
  z-index: 2; 
}

.splash-screen .submit-button {
  background-color: #F5F5F5;
  position: relative;
  z-index: 1; 
  width: 70px;
}

.checkbox-container {
  display: flex;
  align-items: center; 
  margin-left: -1px;
}

.keep-logged-in-checkbox {
  margin-right: 10px; 
  height: 20px; 
  transform: scale(1.5);
}

.checkbox-label {
  color: #F5F5F5;
  font-size: small;
}

.forgot-access-code-link {
  background: none;
  border: none;
  color: #DDEAFF; /* Choose a color that fits the theme */
  cursor: pointer;
  font-size: samll;
  padding-right: 0;
}


/*  Dropdown menu  */

.user-menu {
  position: relative;
}

.initials {
  color: #F5F5F5;
  background-color: transparent; 
  border: 1px solid; 
  border-radius: 50%;
  padding: 0.5em;
  display: inline-block;
  text-align: center; 
  width: 20px; 
  height: 20px; 
  line-height: 20px;
  cursor: pointer;
  font-size: 0.8em;
  user-select: none;
}

.dark-mode .initials {
  color: #bdbdbd;
}

.dropdown {
  position: absolute;
  top: 42px;
  right: 0;
  background-color: #F5F5F5;
  border: 0.5px solid #5E1C65;
  box-shadow: 0 2px 5px #5e1c6520;
  z-index: 1000;
  color: #333;
  border-radius: 1px;
  width: 180px;
}

@media (max-width: 768px) {
  .dropdown {
    right: -1rem;
  }
}

.dark-mode .dropdown {
  color: #bdbdbd;
  background-color: #242424;
  border: 0.5px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  padding: 0.4em 1em;
  user-select: none;
  display: flex;
  align-items: center;
}

.dropdown-item-text {
  font-size: smaller;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #e4a2f125;
}

.dark-mode .clickable:hover {
  background-color: #f5f5f515;
}

.dropdown-symbol {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  margin-right: 0.5rem; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 320px;
}

.dark-mode .modal {
  background-color: #1D1D1D;
}

.modal h3 {
  margin-top: 0;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #C855E280;
  border-radius: 1px;
  background-color: white;
}

.modal input:focus {
  outline: 1px solid #83019280;
}

.dark-mode .modal input {
  border: 1px solid #bdbdbd90;
  background-color: #242424;
  color: #B7B7B7;
}

.dark-mode .modal input:focus {
  outline: 1px solid #adadad60;
}

.dark-mode .modal input::placeholder {
  opacity: 1;
}

.modal button {
  padding: 10px;
  border: none;
  border-radius: 1px;
  margin-top: 3px;
  cursor: pointer;
}

.modal button[type="submit"] {
  background-color: #830192;
  color: #F5F5F5;
}

.modal button:not([type="submit"]) {
  background-color: #b7b7b783;
  margin-left: 10px;
}

.dark-mode .modal button[type="submit"] {
  background-color: hsl(294, 45%, 45%);
  color: #F5F5F5;
}

.dark-mode .modal button:not([type="submit"]) {
  background-color: #b7b7b780;
  margin-left: 10px;
  color: #F5F5F5;
}

.username-header {
  font-family: 'Poppins',sans-serif;
  margin-bottom: 0.5em;
}

.modal-error-message {
  height: 20px; 
  margin-top: -7px; 
  font-size: 0.75em;
  text-align: center;
  color: transparent; 
}

.modal-error-message.active {
  color: #CF000F; 
}

.splash-error-message {
  height: 20px; 
  margin-top: -8px; 
  font-size: 0.8em;
  margin-left: 72px;
  color: transparent; 
}

.splash-error-message.active {
  color: #FFEBEC; 
}

.loading-container {
  display: flex;
  align-items: center;
}

 /* .COLOUR_MAP_LIGHT {
  color: hsl(191, 100%, 44%);
  color: hsl(94, 64%, 51%);
  color: hsl(356, 100%, 41%);
  color: hsl(45, 98%, 43%);
  color: hsl(191, 40%, 46%);
  color: hsl(94, 41%, 51%);
  color: hsl(356, 40%, 41%);
  color: hsl(45, 40%, 45%)
}  */